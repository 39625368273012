<template>
  <div class="bg-grey5">
    <div class="d-flex card-header">
      <img src="/assets/img/logo/3.png" alt="" class="company-logo">
      <div class="date-block df-c fs16 w-100">
        15/01/2021 - 18/01/2021
      </div>
    </div>
    <div class="p5 p-relative">
      <img src="/assets/img/icons/share-alt-light-black.svg" alt="" class="share-icon">
      <h5>The Franchise Show</h5>
      <p class="fs12 desc-block">
        Trade Fair for franchising and business
      </p>
      <p class="fs12">
        professional visitors only
      </p>
      <p class="fs14 color-blue7">
        Cobb Galleria Centre, Atlanta
      </p>
      <div class="d-flex justify-content-between m-t-10">
        <country-flag country='fr' size='big'/>
        <button class="primary-btn primary-btn_sm d-flex info-btn" @click="closeModal">
          <img src="/assets/img/icons/info-white.svg" alt="" class="mx5">
          {{ $t("TOURISM.INFO") }}
        </button>  
        <router-link :to="{ name: 'SelectedResult' }" class="primary-btn primary-btn_sm d-flex bg-green7">
          {{ $t("TOURISM.BOOK_A_DEAL") }}
        </router-link>  
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExhibitionBookItem',
  props: {
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header {
  img {
    height: 70px;
    width: 70px;
  }
  .date-block {
    background-color: #6F808C;
    color: #fff;
  }
}

// .country-banner {
//   height: 36px;
//   object-fit: contain;
// }

.desc-block {
  height: 5em;
}

.info-btn {
  width: auto;
  padding: 0 5px;
}

.share-icon {
  position: absolute;
  right: 5px;
  top: 3px;
}

.rtl-type {
  .share-icon {
    right: unset;
    left: 5px;
  }
}
</style>