import { render, staticRenderFns } from "./TradeShowsDetails.vue?vue&type=template&id=24cbd305&scoped=true&"
import script from "./TradeShowsDetails.vue?vue&type=script&lang=js&"
export * from "./TradeShowsDetails.vue?vue&type=script&lang=js&"
import style0 from "./TradeShowsDetails.vue?vue&type=style&index=0&id=24cbd305&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24cbd305",
  null
  
)

export default component.exports