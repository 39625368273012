<template>
   <modal
    :width="1150"
    :adaptive="true"
    class="tradeDetailModal CEModal modal-scroll-bar"
    name="TradeDetailBookModal"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>

    <div class="df-c">
      <img src="/assets/img/logo/3.png" alt="" class="company-logo mx20">
      <h4>
        The Franchise Show, Atlanta, US
      </h4>
    </div>
    <div class="row m-t-20">
      <div class="col-sm-4">
        <ExhibitionBookItem @closeModal="closeModal"></ExhibitionBookItem>
      </div>
      <div class="col-sm-4">
        <ExhibitionBookItem @closeModal="closeModal"></ExhibitionBookItem>
      </div>
      <div class="col-sm-4">
        <ExhibitionBookItem></ExhibitionBookItem>
      </div>
      <div class="col-sm-4">
        <ExhibitionBookItem></ExhibitionBookItem>
      </div>
      <div class="col-sm-4">
        <ExhibitionBookItem></ExhibitionBookItem>
      </div>
      <div class="col-sm-4">
        <ExhibitionBookItem></ExhibitionBookItem>
      </div>
    </div>
  </modal>
</template>

<script>
import ExhibitionBookItem from "./ExhibitionBookItem.vue" 
export default {
  name: 'TradeDetailBookModal',
  components: {
    ExhibitionBookItem
  }, 
  methods: {
    closeModal() {
      this.$modal.hide("TradeDetailBookModal");
    },
  }
}
</script>

<style lang="scss">
  .tradeDetailModal .vm--modal{
    padding: 20px 50px;
  }
</style>

<style lang="scss" scoped>
.tradeDetailModal .company-logo {
  width: 70px;
  height: 70px;
}
</style>